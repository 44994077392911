import React, { useState, useCallback, SyntheticEvent } from "react"
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { LanguageMenu } from './language_menu';

type Props = {
  toggleMenu: () => void
};

const PhoneLang: React.FC<{ className?: string }> = ({ className = '' }) => (
  <div className={`phone-lang ${className}`}>
    {/* <p className="telephone">T. +30 210 940 0607</p> */}
    {/*<LanguageMenu/>*/}
  </div>
);

const Menu: React.FC<Props> = ({ toggleMenu }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const toggleSubMenu = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded(state => !state);
    return false;
  }

  return (
      <nav role="navigation">
        <h1 className="logo"><Link className="logo" to="/">UV Tech by RENDEV</Link></h1>
        <button className="menu-btn" onClick={toggleMenu}>
          Open
        </button>

        <ul className="menu">
          <li><Link to="/vision">{t("header.navigation.vision")}</Link></li>
          <li><Link to="/company">{t("header.navigation.company")}</Link></li>
          <li><Link to="/technology">{t("header.navigation.technology")}</Link></li>
          <li><Link to="/references">{t("header.navigation.references")}</Link></li>
          <li><Link to="/brochures">{t("header.navigation.brochures")}</Link></li>
          <li className="contact"><Link to="/contact">{t("header.navigation.contact")}</Link></li>
        </ul>
        <PhoneLang className="mobile" />
      </nav>
  );
};

export const Header: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleMenu = useCallback(() => setExpanded(state => !state), []);
  return (
      <header className={`header ${expanded ? 'expanded' : ''}`}>
        <div className="upper-header">
          <PhoneLang className="desktop" />
        </div>
        <Menu
          toggleMenu={toggleMenu}
        />
      </header>
  );
};
