import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "gatsby-plugin-react-i18next";

export const Footer: React.FC = () => {
  const {t} = useTranslation();
  const itemsSkeptron = [1, 2, 3, 4, 5, 6];
  const itemsHyperion = [1, 2];

  return (
    <footer>
      <div className="request">
        <h3>{t('common.request.heading')}</h3>
        <Link to="/contact" className="button">{t('common.request.button')}</Link>
      </div>
      <div className="footer-content">
        <div className="footer-bottom">
          <p className="copyright">© Copyright UVX Solutions - All rights reserved</p>
        </div>
      </div>
    </footer>
  );
}
